import React from "react";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet-async";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import MaterialTable from "material-table";
import styled from "styled-components/macro";
import { spacing } from "@material-ui/system";
import {
  Chip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  List,
  TextField,
  Typography as MuiTypography,
} from "@material-ui/core";
import Panel from "../../components/panels/Panel";
import { useApp } from "../../AppProvider";
import Loader from "../../components/Loader";

import Isemail from "isemail";
import useFetchData from "../../hooks/useFetchData";
import { Autocomplete } from "@material-ui/lab";

const Divider = styled(MuiDivider)(spacing);
const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)}px);
  height: 100%;
  width: 100%;
`;

const GroupHeader = styled(Typography)(({ theme }) => ({
  position: "sticky",
  top: "-8px",
  fontSize: "1rem",
  paddingLeft: "8px",
  color: "#fff",
  backgroundColor: theme.palette.primary.light,
  fontWeight: 600,
  zIndex: 1,
}));

const GroupItems = styled(List)({
  padding: 0,
});

const StyledListItem = styled("div")`
  padding: 8px 16px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  font-weight: 600;
  margin: 0;
`;

const formatRouteLabel = (option) => (
  <>
    <strong>{option.sidebar_route_name}</strong>
    {option?.parent_name && (
      <span
        style={{
          fontStyle: "italic",
          color: "gray",
          marginLeft: "4px",
        }}
      >
        ({option.parent_name})
      </span>
    )}
  </>
);

function UsersHome() {
  const { doToast } = useApp();
  const { getAccessTokenSilently } = useAuth0();

  const [SidebarRoutes] = useFetchData("list-sidebar-routes/sidebar");

  const { data: UserRoles } = useQuery(
    ["UserRoles"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-user-roles`,
          { headers }
        );
        const manipulatedData = {};
        data.forEach(
          (item) =>
            (manipulatedData[item.auth0_role_id] = item["auth0_role_name"])
        );
        return manipulatedData;
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { data, isLoading, error, refetch } = useQuery(
    ["users"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
          { headers }
        );
        return data;
      } catch (err) {
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  function validateEmail(emailInput) {
    if (!emailInput) return false;
    return Isemail.validate(emailInput);
  }

  const editTableColumns = [
    {
      title: "User Id",
      field: "auth0_user_id",
      editable: "never",
      hidden: true,
    },
    {
      title: "Full-name",
      field: "auth0_name",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      validate: (rowData) =>
        !rowData.auth0_name
          ? {
              isValid: false,
              helperText: "Requires Full-name",
            }
          : true,
    },
    {
      title: "Nickname",
      field: "auth0_nickname",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      validate: (rowData) =>
        !rowData.auth0_nickname
          ? {
              isValid: false,
              helperText: "Requires Nickname",
            }
          : true,
    },
    {
      title: "Landing Page",
      field: "assigned_role",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      initialEditValue: "rol_whLG1ohPFpYJMuKy",
      lookup: UserRoles,
    },
    {
      title: "Email",
      field: "auth0_email",
      cellStyle: {
        minWidth: 300,
        maxWidth: 300,
      },
      validate: (rowData) =>
        !validateEmail(rowData?.auth0_email)
          ? {
              isValid: false,
              helperText: "Requires Email",
            }
          : true,
    },
    {
      title: "Password",
      field: "auth0_password",
      cellStyle: {
        minWidth: 200,
        maxWidth: 200,
      },
      editable: "onAdd",
      render: () => "**********",
      validate: (rowData) =>
        !rowData?.auth0_password
          ? {
              isValid: false,
              helperText: "Requires Password",
            }
          : rowData?.auth0_password?.length < 8
          ? {
              isValid: false,
              helperText: "Must be at least 8 characters",
            }
          : true,
    },
    {
      title: "Visible Pages",
      field: "associated_sidebar_routes",
      initialEditValue: [14, 1],
      cellStyle: {
        minWidth: 1000,
        maxWidth: 1000,
      },
      render: (rowData) => {
        if (!rowData?.associated_sidebar_routes?.length) {
          return "None";
        }
        return rowData.associated_sidebar_routes
          .map((routeId) => {
            const found = SidebarRoutes?.find(
              (route) => route.sidebar_route_ndx === routeId
            );
            return found?.sidebar_route_name ?? routeId;
          })
          .join(", ");
      },
      editComponent: ({ value = [], onChange }) => {
        const selectedRoutes = SidebarRoutes?.filter((route) =>
          value?.includes(route.sidebar_route_ndx)
        );
        return (
          <Autocomplete
            multiple
            disableCloseOnSelect
            size="small"
            groupBy={(option) => option.header_name}
            options={SidebarRoutes || []}
            value={selectedRoutes}
            getOptionLabel={(option) =>
              option?.parent_name
                ? `${option.parent_name}: ${option.sidebar_route_name}`
                : option.sidebar_route_name
            }
            getOptionSelected={(option, value) =>
              option.sidebar_route_ndx === value.sidebar_route_ndx
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  key={option.sidebar_route_ndx}
                  label={formatRouteLabel(option)}
                  {...getTagProps({ index })}
                  style={{
                    margin: "4px",
                  }}
                />
              ))
            }
            renderOption={(option) => (
              <StyledListItem>{formatRouteLabel(option)}</StyledListItem>
            )}
            onChange={(event, newValue) => {
              const newIds = newValue.map((route) => route.sidebar_route_ndx);
              onChange(newIds);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                color="primary"
                placeholder="Visible Pages"
                variant="standard"
              />
            )}
            renderGroup={(params) => (
              <div key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </div>
            )}
          />
        );
      },
    },
  ];

  const handleAdd = (newData) => {
    return (async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };
        await axios.post(
          `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
          {
            email: newData.auth0_email,
            name: newData.auth0_name,
            nickname: newData.auth0_nickname,
            password: newData.auth0_password,
            role: newData.assigned_role,
            sidebarRoutes: newData.associated_sidebar_routes ?? [],
          },
          { headers }
        );

        await refetch();
        doToast("success", "This user was successfully created");
      } catch (err) {
        doToast("error", "Something went wrong");
        if (axios.isCancel(err)) {
          console.log(`call was cancelled`);
        } else {
          console.error(err);
        }
      }
    })();
  };

  const handleDelete = (oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.delete(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users/${oldData.auth0_user_id}`,
            { headers }
          );
          await refetch();
          doToast("success", "This user was deleted from the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  const handleUpdate = (newData, oldData) => {
    return (async () => {
      try {
        if (oldData) {
          const token = await getAccessTokenSilently();
          const headers = { Authorization: `Bearer ${token}` };
          await axios.put(
            `${process.env.REACT_APP_ENDPOINT}/api/users-management/users`,
            {
              id: newData.auth0_user_id,
              email: newData.auth0_email,
              name: newData.auth0_name,
              nickname: newData.auth0_nickname,
              role: newData.assigned_role,
              sidebarRoutes: newData.associated_sidebar_routes ?? [],
            },
            { headers }
          );

          await refetch();
          doToast("success", "This user was updated in the database");
        } else {
          doToast("error", "Something went wrong");
        }
      } catch (err) {
        console.error(err);
        const message = err?.message ?? "Something went wrong";
        doToast("error", message);
      }
    })();
  };

  if (error) return "An error has occurred: " + error.message;

  return (
    <React.Fragment>
      <Helmet title="User Management" />
      <Typography variant="h3" gutterBottom display="inline">
        User Management
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Panel>
            <TableWrapper>
              {data ? (
                <MaterialTable
                  id="Users Management"
                  columns={editTableColumns}
                  data={data}
                  editable={{
                    onRowDelete: handleDelete,
                    onRowAdd: handleAdd,
                    onRowUpdate: handleUpdate,
                  }}
                  localization={{
                    toolbar: { searchPlaceholder: "Search Users" },
                  }}
                  components={{
                    Container: (props) => <div {...props} />,
                  }}
                  isLoading={isLoading}
                  options={{
                    emptyRowsWhenPaging: false,
                    addRowPosition: "first",
                    pageSize: 30,
                    pageSizeOptions: [5, 10, 30, 60],
                    searchFieldAlignment: "left",
                    showTitle: false,
                    maxBodyHeight: "600px",
                  }}
                />
              ) : (
                <Loader />
              )}
            </TableWrapper>
          </Panel>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default UsersHome;
