import React, { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { findRawRecords } from "./services/crudService";
import { CRUD_LOOKUP_TABLES } from "./constants";
import axios from "axios";
import { ErrorCard } from "./components/crud/ErrorCard";
import { Button, CircularProgress } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";

export const AppContext = React.createContext();
export const useApp = () => useContext(AppContext);

export const AppProvider = ({ children }) => {
  // User
  const { user } = useAuth0();
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    if (user) {
      let myUser = { ...user };
      const roles = myUser[`${process.env.REACT_APP_AUDIENCE}/roles`];

      myUser.home = "/water-acquisition-tool/wadt-map";

      if (roles && roles.filter((x) => x === "Developer").length > 0) {
        myUser.isDeveloper = true;
      } else {
        myUser.isDeveloper = false;
      }

      if (roles && roles.filter((x) => ["Developer"].includes(x)).length > 0) {
        myUser.isAdmin = true;
      }

      if (roles && roles.filter((x) => ["Accounting"].includes(x)).length > 0) {
        myUser.home = "/home/accounting";
      }

      setCurrentUser(myUser);
    }
  }, [user]);

  const { getAccessTokenSilently } = useAuth0();

  const [lookupTableCache, setLookupTableCache] = useState([]);

  useEffect(() => {
    let isMounted = true;

    const loadModels = async () => {
      const modelsToLoad = CRUD_LOOKUP_TABLES;
      const token = await getAccessTokenSilently();
      const myLookupTableCache = {};

      for (const model of modelsToLoad) {
        try {
          const records = await findRawRecords(model, token);
          if (!isMounted) return;
          myLookupTableCache[model] = records;
        } catch (err) {
          console.error(err);
        }
      }

      if (isMounted) {
        setLookupTableCache(myLookupTableCache);
      }
    };

    if (user) {
      loadModels();
    }

    return () => {
      isMounted = false;
    };
  }, [user, getAccessTokenSilently]);

  // Toast
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastSeverity, setToastSeverity] = useState("success");
  const [toastOptions, setToastOptions] = useState({});

  const doToast = (severity, message, options) => {
    setToastMessage(message);
    setToastSeverity(severity);
    setToastOpen(true);
    setToastOptions(options);
  };

  const toastValues = {
    toastOpen,
    toastMessage,
    toastSeverity,
    toastOptions,
    setToastOpen,
    setToastMessage,
    setToastSeverity,
    setToastOptions,
    doToast,
  };

  // Confirm Dialog
  const [confirmDialogKey, setConfirmDialogKey] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogPayload, setConfirmDialogPayload] = useState(null);
  const [confirmDialogCallback, setConfirmDialogCallback] = useState(
    async () => {}
  );

  const confirmationDialogValues = {
    confirmDialogKey,
    setConfirmDialogKey,
    confirmDialogOpen,
    setConfirmDialogOpen,
    confirmDialogPayload,
    setConfirmDialogPayload,
    confirmDialogCallback,
    setConfirmDialogCallback,
  };

  const [userSidebarRoutes, setUserSidebarRoutes] = useState([]);
  const [sidebarRoutesError, setSidebarRoutesError] = useState(null);
  const [loadingSidebarRoutes, setLoadingSidebarRoutes] = useState(true);

  useEffect(() => {
    let isMounted = true;

    const fetchSidebarRoutes = async () => {
      if (!user) {
        setLoadingSidebarRoutes(false);
        return;
      }
      try {
        const token = await getAccessTokenSilently();
        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/user-sidebar-routes`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (isMounted) {
          setUserSidebarRoutes(data);
        }
      } catch (error) {
        console.error("Error fetching user sidebar routes:", error);
        if (isMounted) {
          setSidebarRoutesError(
            "Failed to load sidebar routes. Please try again."
          );
        }
      } finally {
        if (isMounted) {
          setLoadingSidebarRoutes(false);
        }
      }
    };

    fetchSidebarRoutes();

    return () => {
      isMounted = false;
    };
  }, [user, getAccessTokenSilently]);

  if (loadingSidebarRoutes) {
    return (
      <CircularProgress
        style={{
          position: "absolute",
          left: "50%",
          bottom: "50%",
          width: "100px",
          height: "100px",
        }}
        m={2}
        color="secondary"
      />
    );
  }

  if (sidebarRoutesError) {
    return (
      <ErrorCard
        title="Invalid Record"
        subtitle="It might have been removed."
        actions={
          <Button
            variant="contained"
            color="primary"
            startIcon={<ChevronLeft />}
            onClick={() => window.location.reload()}
          >
            Refresh Page
          </Button>
        }
      />
    );
  }

  return (
    <AppContext.Provider
      value={{
        currentUser,
        lookupTableCache,
        ...toastValues,
        ...confirmationDialogValues,
        userSidebarRoutes,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
