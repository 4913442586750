import React, { useMemo } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  authLayoutRoutes,
  dashboardLayoutRoutes,
  dashboardMaxContentLayoutRoutes,
  fullscreenMapRoutes,
  presentationLayoutRoutes,
} from "./index";

import Auth0ProviderWithHistory from "../auth/auth0-provider-with-history";
import {
  Dashboard as DashboardLayout,
  DashboardMaxContent as DashboardMaxContentLayout,
} from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import PresentationLayout from "../layouts/Presentation";
import FullscreenMapLayout from "../layouts/FullscreenMap";
import Page404 from "../pages/auth/Page404";
import ProtectedRoute from "../auth/ProtectedRoute";
import ProtectedPage from "../pages/protected/ProtectedPage";
import { DevProvider } from "../DevProvider";
import { AppProvider, useApp } from "../AppProvider";
import ScrollToTop from "../hooks/ScrollToTop";
import Unauthorized from "../components/Unauthorized";

const ProviderStub = ({ children }) => <>{children}</>;

const ChildRoutes = (Layout, routes, userPaths) => {
  return routes.map((route, index) => {
    const {
      component: Component,
      guard: Guard = React.Fragment,
      provider: Provider = ProviderStub,
      children,
      path,
      mandatoryRoute,
    } = route;

    if (children) {
      // Recursively handle children
      return ChildRoutes(Layout, children, userPaths);
    }

    if (!path) return null;

    const isAuthorized = userPaths.includes(path) || mandatoryRoute;

    return (
      <Route
        key={index}
        path={path}
        exact
        render={(props) => (
          <Layout>
            <Guard>
              <Provider>
                {isAuthorized ? <Component {...props} /> : <Unauthorized />}
              </Provider>
            </Guard>
          </Layout>
        )}
      />
    );
  });
};

const AppWithProviders = () => (
  <AppProvider>
    <DevProvider>
      <ScrollToTop />
      <ApplicationRoutes />
    </DevProvider>
  </AppProvider>
);

const ApplicationRoutes = () => {
  const { userSidebarRoutes } = useApp();
  const userPaths = useMemo(
    () => userSidebarRoutes.map((r) => r.sidebar_route_path),
    [userSidebarRoutes]
  );

  return (
    <Switch>
      {ChildRoutes(DashboardLayout, dashboardLayoutRoutes, userPaths)}
      {ChildRoutes(
        DashboardMaxContentLayout,
        dashboardMaxContentLayoutRoutes,
        userPaths
      )}
      {ChildRoutes(AuthLayout, authLayoutRoutes, userPaths)}
      {ChildRoutes(PresentationLayout, presentationLayoutRoutes, userPaths)}
      {ChildRoutes(FullscreenMapLayout, fullscreenMapRoutes, userPaths)}
      <ProtectedRoute path="/secret" component={ProtectedPage} />
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
  );
};

const Routes = () => (
  <Router>
    <Auth0ProviderWithHistory>
      <AppWithProviders />
    </Auth0ProviderWithHistory>
  </Router>
);

export default Routes;
