/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Home,
  Users,
  Map as MapIcon,
  Droplet,
  Edit,
  BarChart2,
} from "react-feather";

import Landing from "../pages/presentation/Landing";

// TODO MAYBE LAZY IMPORT
import WadtMap from "../pages/publicMap/wadtMap";
import WaterSourcesManagement from "../pages/dataManagement/wadt/WaterSources/WaterSourcesManagement";
import WaterSourceManagement from "../pages/dataManagement/wadt/WaterSources/WaterSourceManagement";
import { AttachMoney, BorderColor, Loop, TableChart } from "@material-ui/icons";
import AcquisitionsAndOpportunitiesManagement from "../pages/dataManagement/wadt/AcquisitionsAndOpportunitiesManagement/AcquisitionsAndOpportunitiesManagement";
import AcquisitionAndOpportunityManagement from "../pages/dataManagement/wadt/AcquisitionsAndOpportunitiesManagement/AcquisitionAndOpportunityManagement";
import NewAcquisitionAndOpportunityManagement from "../pages/dataManagement/wadt/AcquisitionsAndOpportunitiesManagement/NewAcquisitionAndOpportunityManagement";
import EditWaterSourcesManagement from "../pages/dataManagement/wadt/EditWaterSources/EditWaterSourcesManagement";
import EditWaterSourceManagement from "../pages/dataManagement/wadt/EditWaterSources/EditWaterSourceManagement";
import WaterSourceChangeLogManagement from "../pages/dataManagement/wadt/ChangeLog/WaterSourceChangeLogManagement";
import WaterSourcesChangeLogsManagement from "../pages/dataManagement/wadt/ChangeLog/WaterSourcesChangeLogsManagement";
import Accounting from "../pages/dashboards/Accounting";
import SiteSummaryTable from "../pages/waterAccounting/SiteSummaryTable";
import DailyProduction from "../pages/waterAccounting/DailyProduction/DailyProduction";
import ClimateCenter from "../pages/waterAccounting/ClimateCenter/ClimateCenter";
import PoudrePondsScadaViewer from "../pages/waterAccounting/PoudrePoundsScadaViewer/PoudrePondsScadaViewer";
import PoudrePonds234 from "../pages/waterAccounting/PoudrePonds234/PoudrePonds234";
import DwrStreamflowViewer from "../pages/waterAccounting/DwrStreamflowViewer/DwrStreamflowViewer";
import RiverCalls from "../pages/waterAccounting/dataReviewDownload/riverCalls/RiverCalls";
import NorthernData from "../pages/waterAccounting/dataReviewDownload/NorthernData";
import QueryAndDownload from "../pages/waterAccounting/dataReviewDownload/queryAndDownload";
import WtpDataAdjustments from "../pages/dataEntryEditing/wtpDataAdjustments/WtpDataAdjustments";
import ManualDataEntry from "../pages/dataEntryEditing/manualDataEntry/ManualDataEntry";
import UsersHome from "../pages/users/UsersHome";
import AccountingAdjustments from "../pages/dataEntryEditing/accountingAdjustments/AccountingAdjustments";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const dashboardsRoutes = {
  header: "Water Accounting",
  id: "Dashboards",
  icon: <Home />,
  children: [
    {
      name: "Current Conditions",
      path: "/home/accounting",
      component: Accounting,
      children: null,
      containsHome: true,
    },
    {
      path: "/water-accounting/dashboards/climate-center",
      name: "Climate Center",
      component: ClimateCenter,
    },
    {
      path: "/water-accounting/dashboards/recent-streamflows",
      name: "Recent Streamflows",
      component: DwrStreamflowViewer,
    },
  ],
};

const poudrePondsRoutes = {
  header: "Water Accounting",
  id: "Poudre Ponds",
  icon: <BarChart2 />,
  children: [
    {
      path: "/water-accounting/poudre-ponds/daily-accounting",
      name: "Daily Accounting",
      component: PoudrePonds234,
    },
    {
      path: "/water-accounting/poudre-ponds/1031-values",
      name: "10/31 Values",
      component: ManualDataEntry,
    },
  ],
};

const dataReviewRoutes = {
  header: "Water Accounting",
  id: "Data Review",
  icon: <TableChart />,
  children: [
    {
      path: "/water-accounting/data-review/wtp-production",
      name: "WTP Production",
      component: DailyProduction,
    },
    {
      path: "/water-accounting/data-review/poudre-ponds-scada",
      name: "Poudre Ponds SCADA",
      component: PoudrePondsScadaViewer,
    },
    {
      path: "/water-accounting/data-review/river-calls",
      name: "River Calls",
      component: RiverCalls,
    },
    {
      path: "/water-accounting/data-review/northern-data",
      name: "Northern Data",
      component: NorthernData,
    },
    {
      path: "/water-accounting/data-review/site-summary-table",
      name: "Site Summary Table",
      component: SiteSummaryTable,
    },
  ],
};

const accountingToolsRoutes = {
  header: "Water Accounting",
  id: "Accounting Tools",
  icon: <BorderColor />,
  children: [
    {
      path: "/water-accounting/accounting-tools/wtp-data-adjustments",
      name: "WTP Data Adjustments",
      component: WtpDataAdjustments,
    },
    {
      path: "/water-accounting/accounting-tools/accounting-adjustments",
      name: "Accounting Adjustments",
      component: AccountingAdjustments,
    },
    {
      path: "/water-accounting/accounting-tools/query-and-download",
      name: "Query & Download",
      component: QueryAndDownload,
    },
  ],
};

const wadtMapRoutes = {
  header: "Water Acquisition Tool",
  id: "WADT Map",
  icon: <MapIcon />,
  path: "/water-acquisition-tool/wadt-map",
  component: WadtMap,
};

const waterSourcesRoute = {
  header: "Water Acquisition Tool",
  id: "WADT Sources",
  icon: <Droplet />,
  path: "/water-acquisition-tool/wadt-sources",
  component: WaterSourcesManagement,
};

const editWaterSourcesRoute = {
  header: "Water Acquisition Tool",
  icon: <Edit />,
  id: "WADT Sources Data Management",
  path: "/water-acquisition-tool/water-sources-data-management",
  component: EditWaterSourcesManagement,
};

const waterSourcesChangeLogsRoute = {
  header: "Water Acquisition Tool",
  id: "WADT Sources Change Logs",
  icon: <Loop />,
  path: "/water-acquisition-tool/water-sources-change-logs",
  component: WaterSourcesChangeLogsManagement,
};

const acquisitionsAndOpportunitiesRoute = {
  header: "Water Acquisition Tool",
  id: "Acquisitions & Opportunities",
  icon: <AttachMoney />,
  path: "/water-acquisition-tool/acquisitions-and-opportunities",
  component: AcquisitionsAndOpportunitiesManagement,
};

const userManagementRoutes = {
  header: "System Admin",
  id: "User Management",
  icon: <Users />,
  path: "/system-admin/user-management",
  component: UsersHome,
};

const waterSourceRoute = {
  path: "/water-acquisition-tool/water-sources/:id",
  component: WaterSourceManagement,
};

const editWaterSourceRoute = {
  path: "/water-acquisition-tool/water-sources-data-management/:id",
  component: EditWaterSourceManagement,
};

const waterSourceChangeLogRoute = {
  path: "/water-acquisition-tool/water-sources-change-logs/:id",
  component: WaterSourceChangeLogManagement,
};

const acquisitionAndOpportunityRoute = {
  path: "/water-acquisition-tool/acquisitions-and-opportunities/:id",
  component: AcquisitionAndOpportunityManagement,
};

const newAcquisitionAndOpportunityRoute = {
  path: "/water-acquisition-tool/acquisitions-and-opportunities/new/:id",
  component: NewAcquisitionAndOpportunityManagement,
};

const accountRoutes = {
  path: "/account",
  component: Account,
  mandatoryRoute: true,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      component: Profile,
      mandatoryRoute: true,
    },
    {
      path: "/auth/logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
      mandatoryRoute: true,
    },
  ],
};

const landingRoutes = {
  path: "/",
  component: Landing,
  mandatoryRoute: true,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  dashboardsRoutes,
  poudrePondsRoutes,
  dataReviewRoutes,
  accountingToolsRoutes,
  waterSourcesRoute,
  waterSourceRoute,
  waterSourcesChangeLogsRoute,
  waterSourceChangeLogRoute,
  editWaterSourcesRoute,
  editWaterSourceRoute,
  acquisitionsAndOpportunitiesRoute,
  acquisitionAndOpportunityRoute,
  newAcquisitionAndOpportunityRoute,
  userManagementRoutes,
];

export const dashboardMaxContentLayoutRoutes = [wadtMapRoutes];

// Routes using the Auth layout
export const authLayoutRoutes = [accountRoutes];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  dashboardsRoutes,
  poudrePondsRoutes,
  dataReviewRoutes,
  accountingToolsRoutes,
  wadtMapRoutes,
  waterSourcesRoute,
  editWaterSourcesRoute,
  waterSourcesChangeLogsRoute,
  acquisitionsAndOpportunitiesRoute,
  userManagementRoutes,
];
