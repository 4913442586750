import React, { useMemo, useState } from "react";
import styled from "styled-components/macro";
import { darken, rgba } from "polished";
import { NavLink, withRouter } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "../vendor/perfect-scrollbar.css";

import {
  Avatar,
  Badge,
  Box,
  Chip,
  Collapse,
  Drawer as MuiDrawer,
  Grid,
  List as MuiList,
  ListItem,
  ListItemText,
  Typography,
} from "@material-ui/core";

import { ExpandLess, ExpandMore } from "@material-ui/icons";

import { sidebarRoutes as routes } from "../routes/index";
import { useAuth0 } from "@auth0/auth0-react";
import UserDropdown from "./UserDropdown";
import { customSecondary } from "../theme/variants";
import { useApp } from "../AppProvider";

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
`;

const Brand = styled(ListItem)`
  font-size: ${(props) => props.theme.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(6)}px;
  padding-right: ${(props) => props.theme.spacing(6)}px;
  justify-content: flex-start;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled.img`
  margin-right: ${(props) => props.theme.spacing(2)}px;
`;

const Category = styled(ListItem)`
  padding-top: ${(props) => props.theme.spacing(3)}px;
  padding-bottom: ${(props) => props.theme.spacing(3)}px;
  padding-left: ${(props) => props.theme.spacing(8)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
    opacity: 0.5;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.08);
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(4)}px;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const Link = styled(ListItem)`
  padding-left: ${(props) => props.theme.spacing(17.5)}px;
  padding-top: ${(props) => props.theme.spacing(2)}px;
  padding-bottom: ${(props) => props.theme.spacing(2)}px;

  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.7)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: ${(props) =>
      darken(0.015, props.theme.sidebar.background)};
  }

  &.${(props) => props.activeClassName} {
    background-color: ${(props) =>
      darken(0.03, props.theme.sidebar.background)};

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: ${(props) => props.theme.typography.body1.fontSize}px;
  }
  margin-top: 0;
  margin-bottom: 0;
`;

const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${() => customSecondary[500]};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  display: block;
`;

const SidebarFooter = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}px
    ${(props) => props.theme.spacing(4)}px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const SidebarFooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const SidebarFooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const SidebarFooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)}px;
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

const initOpenRoutes = (location) => {
  /* Open collapse element that matches current url */
  const pathName = location.pathname;

  let _routes = {};

  routes.forEach((route, index) => {
    const children = route.children || [];
    const isOpen = route.open;
    const isHome = route.containsHome && pathName === "/";
    let isActive =
      pathName.indexOf(route.path) === 0 ||
      children.filter((x) => x.path === pathName).length > 0;

    _routes = Object.assign({}, _routes, {
      [index]: isActive || isOpen || isHome,
    });
  });

  return _routes;
};

const SidebarCategory = ({
  name,
  icon,
  classes,
  isOpen,
  isCollapsable,
  badge,
  ...rest
}) => {
  return (
    <Category {...rest}>
      {icon}
      <CategoryText>{name}</CategoryText>
      {isCollapsable ? (
        isOpen ? (
          <CategoryIconMore />
        ) : (
          <CategoryIconLess />
        )
      ) : null}
      {badge ? <CategoryBadge label={badge} /> : ""}
    </Category>
  );
};

const SidebarLink = ({ name, to, badge }) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
    >
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

function filterAndDeduplicateRoutes(allRoutes, userPaths, userHeaders) {
  const renderedHeaders = new Set(); // To track rendered headers

  return allRoutes.reduce((acc, category) => {
    const hasChildren = category.children && category.children.length > 0;

    // Filter children based on user paths
    const filteredChildren = hasChildren
      ? category.children.filter((child) => userPaths.includes(child.path))
      : [];

    // Check if this category itself is valid
    const categoryPathIsAllowed =
      category.path && userPaths.includes(category.path);
    const keepHeader =
      category.header &&
      userHeaders.has(category.header) &&
      !renderedHeaders.has(category.header);

    // Skip this category if:
    // - It has no children and the category's path isn't allowed
    // - The header has already been rendered, and no children match
    if (!filteredChildren.length && !categoryPathIsAllowed && !keepHeader) {
      return acc;
    }

    // Add the header to the rendered set if it's included
    if (keepHeader) {
      renderedHeaders.add(category.header);
    }

    // Add the category with filtered children
    acc.push({
      ...category,
      header: keepHeader ? category.header : null, // Render the header only once
      children: filteredChildren, // Include only filtered children
      renderAsButton: !hasChildren && categoryPathIsAllowed, // Button with a valid path
    });

    return acc;
  }, []);
}

const Sidebar = ({ location, staticContext, drawerOpen = true, ...rest }) => {
  const { isAuthenticated, user } = useAuth0();
  const { userSidebarRoutes } = useApp();

  // allowed paths and headers
  const userPaths = useMemo(
    () =>
      userSidebarRoutes
        .filter((r) => r.is_sidebar)
        .map((r) => r.sidebar_route_path),
    [userSidebarRoutes]
  );
  const userHeaders = useMemo(
    () => new Set(userSidebarRoutes.map((r) => r.header_name).filter(Boolean)),
    [userSidebarRoutes]
  );

  // Filter and deduplicate routes
  const filteredRoutes = useMemo(
    () => filterAndDeduplicateRoutes(routes, userPaths, userHeaders),
    [userPaths, userHeaders]
  );

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes(location));

  const toggle = (index) => {
    Object.keys(openRoutes).forEach((item) => {
      if (Number(item) !== index) {
        openRoutes[index] ||
          setOpenRoutes((prev) => ({
            ...prev,
            [item]: false,
          }));
      }
    });
    setOpenRoutes((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Drawer
      variant="permanent"
      {...rest}
      style={{
        pointerEvents: drawerOpen ? "auto" : "none",
      }}
    >
      <Brand
        component={NavLink}
        to="/"
        button
        style={{
          pointerEvents: "all",
        }}
      >
        <BrandIcon
          src={`/static/img/greeley-logo-square.jpg`}
          width="47"
          height="47"
          alt="Greeley Logo"
        />
        <Box ml={1} style={{ fontSize: "0.9rem", display: "flex" }}>
          Water Resources
        </Box>
      </Brand>
      <Scrollbar
        style={{
          display: drawerOpen ? "block" : "none",
          pointerEvents: drawerOpen ? "auto" : "none",
        }}
      >
        <List disablePadding>
          <Items>
            {filteredRoutes.map((category, index) => (
              <React.Fragment key={index}>
                {/* Render the header if it exists */}
                {category.header && (
                  <SidebarSection>{category.header}</SidebarSection>
                )}

                {/* Render children if they exist */}
                {category.children && category.children.length > 0 ? (
                  <>
                    <SidebarCategory
                      isOpen={!openRoutes[index]}
                      isCollapsable
                      name={category.id}
                      icon={category.icon}
                      button
                      onClick={() => toggle(index)}
                    />
                    <Collapse
                      in={openRoutes[index]}
                      timeout="auto"
                      unmountOnExit
                    >
                      {category.children.map((route, idx) => (
                        <SidebarLink
                          key={idx}
                          name={route.name}
                          to={route.path}
                          icon={route.icon}
                          badge={route.badge}
                        />
                      ))}
                    </Collapse>
                  </>
                ) : category.renderAsButton ? (
                  // Render as a standalone button
                  <SidebarCategory
                    isCollapsable={false}
                    name={category.id}
                    to={category.path}
                    icon={category.icon}
                    activeClassName="active"
                    component={NavLink}
                    exact
                    button
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      {isAuthenticated && (
        <SidebarFooter
          style={{
            display: drawerOpen ? "block" : "none",
            pointerEvents: drawerOpen ? "auto" : "none",
          }}
        >
          <Grid container spacing={2}>
            <Grid item>
              <UserDropdown>
                <SidebarFooterBadge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  variant="dot"
                >
                  <Avatar alt={user.name} src={user.picture} />
                </SidebarFooterBadge>
              </UserDropdown>
            </Grid>
            <Grid item>
              <SidebarFooterText variant="body2">{user.name}</SidebarFooterText>
              <SidebarFooterSubText variant="caption">
                {user.email}
              </SidebarFooterSubText>
            </Grid>
          </Grid>
        </SidebarFooter>
      )}
    </Drawer>
  );
};

export default withRouter(Sidebar);
